<template>
  <b-card>
    <validation-observer ref="userInfoValidation">
      <b-form>
        <div class="d-flex mb-2">
          <b-dropdown text="Roles" variant="dark">
            <b-dropdown-item v-for="(item, index) in generalData.roles" :key="index">
              {{ item }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-row>
          <b-col sm="6">
            <b-form-group label="First Name" label-for="account-firstName">
              <validation-provider #default="{ errors }" name="first name" rules="required">
                <b-form-input
                  v-model="generalData.firstName"
                  placeholder="Enter first name here ..."
                  name="firstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Last Name" label-for="account-lastName">
              <validation-provider #default="{ errors }" name="last name" rules="required">
                <b-form-input v-model="generalData.lastName" name="lastName" placeholder="Enter last name here ..." />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <b-form-input v-model="generalData.email" name="email" disabled placeholder="Enter email here ..." />
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group label="Mobile" label-for="account-mobile">
              <b-form-input v-model="generalData.mobile" name="email" placeholder="Enter mobile here ..." />
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Logo" label-for="logo">
              <b-form-file
                ref="file"
                v-model="generalData.logo"
                type="file"
                accept="image/*"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                no-drop
                @change="onChangeLogo"
            /></b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BFormFile,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapActions } from "vuex";

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
    };
  },
  methods: {
    ...mapActions("accountSettings", ["updateUserInfo", "uploadImage"]),
    resetForm() {
      this.generalData.firstName = null;
      this.generalData.firstName = null;
      this.generalData.lastName = null;
      this.generalData.mobile = null;
      this.generalData.logo = null;
    },
    onChangeLogo(event) {
      const formData = new FormData();
      formData.append("logo", event.target.files[0]);
      this.uploadImage(formData);
    },
    validate_phone_number() {
      return new RegExp("^[0-9]{9}$").test(this.generalData.mobile);
    },
    async validationForm() {
      const success = await this.$refs.userInfoValidation.validate();
      if (success) {
        try {
          this.updateUserInfo({
            firstName: this.generalData.firstName,
            lastName: this.generalData.lastName,
            mobile: this.generalData.mobile,
          });
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
